import * as React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { Button, Col, Label, Row } from 'reactstrap';

import {
  BackgroundImage,
  BaseLayout,
  ExternalLink,
  FlexColumn,
  FlexRow,
  Markdown,
  UncontrolledCarousel,
} from '../components';
import homeData from '../data/home.json';

export const IndexPageInternal = ({ bestRates, banners, subSection, whatsNew }: typeof homeData) => {
  const bannerItems = banners.map((b, i) => ({ ...b, caption: '', key: i }));
  return (
    <BaseLayout title="Home">
      <div className="inner-header position-relative">
        <UncontrolledCarousel indicators controls className="w-100" items={bannerItems} />
        <div className="flex-overlay pointer-events-none align-items-start justify-content-end d-none d-md-flex">
          <div className="bg-white p-3 m-5 pointer-events-all" style={{ width: 300 }}>
            <form className="child-spacing-y-2" method="POST" action="https://amimembernet.com/ahcu/username-validate">
              <div>
                <span className="title"><i className="fa fa-lock" /> Account Login</span>
              </div>

              <Label>Username</Label>
              <input
                className="form-control form-control-sm"
                type="text"
                name="username"
                id="username"
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
              />

              <Button block type="submit">Login</Button>
              <div>
                <div>Don&apos;t have an account? <ExternalLink href="https://amimembernet.com/ahcu/registration/agreement">Enroll Now</ExternalLink></div>
                <div>I forgot my <ExternalLink href="https://amimembernet.com/ahcu/forgot-username">username</ExternalLink> or <ExternalLink href="https://amimembernet.com/ahcu/forgot-password" className="exitNotifierLink">password</ExternalLink></div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Row className="py-5 px-3">
        <Col className="child-spacing-y-5" md={{ size: 10, offset: 1 }}>
          <Row className="text-center flex-md-row">
            {
              _.map(bestRates, ({ label, subText, rates }, i) => {
                return (
                  <Col className="best-rate" tag={FlexColumn} justifyAround alignCenter key={i}>
                    <div className="label">{label}</div>
                    <p className="subtext">{subText}</p>
                    <FlexRow alignCenter>
                      {
                        _.map(rates, (rate, i) => {
                          const rateFontSize = 4.5 / rates.length;
                          const sideFontSize = 1.25 / rates.length;
                          const isLast = rates.length - 1 === i;
                          return (
                            <FlexRow key={i} className={classnames({ 'rate-border': !isLast })} alignCenter>
                              <div>
                                <strong className="rate" style={{ fontSize: `${rateFontSize}rem`, lineHeight: `${rateFontSize}rem` }}>{rate.rate}</strong>
                              </div>
                              <FlexColumn>
                                <span className="flex side" style={{ fontSize: `${sideFontSize}rem` }}>{rate.super}</span>
                                <span className="flex side" style={{ fontSize: `${sideFontSize}rem` }}>{rate.sub}</span>
                              </FlexColumn>
                            </FlexRow>
                          );
                        })
                      }
                    </FlexRow>
                  </Col>
                );
              })
            }
          </Row>

          <Row>
            <Col md={8}>
              <h4>{whatsNew.header}</h4>
              <Markdown source={whatsNew.mainMarkdown} />
            </Col>
            <Col className="d-flex align-items-center justify-content-center">
              <Button tag={ExternalLink} href={whatsNew.learnMore.link} className="text-uppercase font-weight-bold" color="primary" size="lg">{whatsNew.learnMore.buttonLabel}</Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <div className="pt-5">
        <div className="position-relative" style={{ height: 400 }}>
          <BackgroundImage src={subSection.src} style={{ height: 400 }} />
          <div className="subsection text-center flex-overlay justify-content-center align-items-center flex-column" style={{ backgroundColor: 'rgba(0, 0, 0, 0.1);' }}>
            <Row>
              <Col md={{ size: 6, offset: 3 }}>
                <h4 className="text-white">{subSection.header}</h4>
                <div className="text-white text-shadow">
                  <Markdown source={subSection.mainMarkdown} />
                </div>
              </Col>
            </Row>
            <div className="info-action">
              <Button tag={ExternalLink} href={subSection.viewLocations.link} size="lg" color="primary">{subSection.viewLocations.buttonLabel}</Button>
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default () => <IndexPageInternal {...homeData} />;
